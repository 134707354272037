import React, { useEffect } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import DateTimeDisplay from './DateTimeDisplay'
import { useCountdown } from '../../hooks/useCountdown'
import { asset } from '../../utils/utils'
import './Countdown.css'
import classNames from 'classnames'
import {pure} from "recompose";
import {useStateValue} from "../state-provider/StateProvider";

const Countdown = ({
  liveStream,
  showNotifiedMessage,
  isWidgetMaximized,
  isSubscribed,
  isReminderFormVisible
}) => {
  const [{ translations }] = useStateValue()

  const [days, hours, minutes, isCountdownFinished] = useCountdown(liveStream?.scheduledAt)

  useEffect(() => {
    if (isCountdownFinished) {
      showNotifiedMessage(false)
    }
  }, [isCountdownFinished])

  return (
    <div className={classNames('countdown-container', isWidgetMaximized ? 'maximized' : 'minimized', {hidden: !isReminderFormVisible})}>
      <p className="title">{liveStream?.title}</p>
      {!isCountdownFinished && <p className="scheduled-at">
        {moment.unix(liveStream?.scheduledAt).format(translations['SubscriptionEventDateTimeFormat'])}
      </p>}
      <div className="show-counter">
        {
          isCountdownFinished ? <span className="previously-live">{translations['SubscriptionFormCountdownFinishedLabel']}</span> :
            <>
              <DateTimeDisplay value={days} type={translations['SubscriptionEventCountdownDaysSuffix']} />
              <DateTimeDisplay value={hours} type={translations['SubscriptionEventCountdownHoursSuffix']} />
              <DateTimeDisplay value={minutes} type={translations['SubscriptionEventCountdownMinutesSuffix']} />
            </>
        }
      </div>

        <>
          { !isCountdownFinished ? <div className={classnames("thank-you-label", { show: isSubscribed })}>
            <span>{translations['SubscriptionFormConfirmationLabel']}</span>
            {<img src={asset('icon-star.svg')} />}
          </div> : null}
        </>
    </div>
  )
}

export default pure(Countdown)
