import classnames from 'classnames'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { isMobile, useMobileOrientation } from 'react-device-detect'
import { Player } from 'video-react'
import { Tooltip } from 'react-tooltip';
import 'video-react/dist/video-react.css'
import FooterLogo from '../footer-logo/FooterLogo'
import PhotoGrid from '../photo-grid/PhotoGrid'
import { useStateValue } from '../state-provider/StateProvider'
import TopBar from '../top-bar/TopBar'
import './Content.css'
import mux from 'mux-embed';
import {log} from '../../utils/log'

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ControlBar from 'video-react/lib/components/control-bar/ControlBar'
import PlayToggle from 'video-react/lib/components/control-bar/PlayToggle'
import FullscreenToggle from 'video-react/lib/components/control-bar/FullscreenToggle'
import CurrentTimeDisplay from 'video-react/lib/components/time-controls/CurrentTimeDisplay'
import DurationDisplay from 'video-react/lib/components/time-controls/DurationDisplay'
import TimeDivider from 'video-react/lib/components/time-controls/TimeDivider'

import { useHistory, useParams } from 'react-router-dom'
import BigPlayButton from 'video-react/lib/components/BigPlayButton'
import ProgressControl from 'video-react/lib/components/control-bar/ProgressControl'
import useEventListener from '../../hooks/useEventListener'
import { asset, formatTime } from '../../utils/utils'
import {
  setIsLoading,
  setIsSetVideoCurrentTime,
  setIsWidgetMaximized,
  setVideoCurrentTime,
  setUserEmail,
  setGridAreaContent,
  navigateCard,
} from '../app/App.action'

import {
  loadVideoTime,
  localStorageAppendToTimestamps,
} from '../../utils/localStorage'
import { EventInteractionType, MetricsType, ws } from '../../events/ws'
import Countdown from '../countdown/Countdown.js'
import { isEmpty } from 'lodash'
import PinnedMessages from '../pinned-live-chat-messages/PinnedMessages'
import ChatContainer from '../chat/ChatContainer'
import ReminderForm from "../form-components/reminder-form/ReminderForm"
import {pure} from "recompose";
import BrandFollowForm from "../form-components/brand-follow-form/BrandFollowForm";
import {sendToWidget} from "../../utils/postMessages";
import {IS_STAGING} from "../../utils/Constants";

let HeaderType = 'Video' | 'Image' | 'LiveStream'

function Content({ prevVideoHeading, setPrevVideoHeading }) {
  const [
    {
      title,
      cardUuid,
      header,
      liveStream,
      liveChat,
      items,
      sequencePollUuid,
      isSetVideoCurrentTime,
      parentCardId,
      isWidgetMaximized,
      isWidgetEmbedded,
      widgetLocation,
      titleStyle,
      isLoading,
      statistics,
      liveData,
      liveChatHistories,
      liveChats,
      brand,
      organizationId,
      isSessionEstablished,
      muxEnvKey,
      sessionData,
      shareUrl,
      translations,
      gridAreaContent,
      liveStreamSubscribed,
    },
    dispatch,
  ] = useStateValue()

  // initiate with true and turn to false in 2 seconds if video fails to load
  const [videoPlayerWorking, setVideoPlayerWorking] = useState(true)
  const [videoPlayerInitiated, setVideoPlayerInitiated] = useState(false)

  const playerRef = useRef(null)
  const [currentLiveStreamUrl, setCurrentLiveStreamUrl] = useState(null)
  const controlBarRef = useRef(null)
  const [volume, setVolume] = useState(1)
  const [livestreamVolume, setLivestreamVolume] = useState(1)
  const [isInitial, setIsInitial] = useState(true)
  const [isInitialLivestream, setIsInitialLivestream] = useState(true)
  const [isPaused, setIsPaused] = useState(false)
  const [isLikeBtnDisabled, disableLikeBtn] = useState(false)
  const [isLikeClicked, clickLike] = useState(false)
  const [livestreamSeekTime, setLivestreamSeekTime] = useState(false)
  const [isNotifiedMessageVisible, showNotifiedMessage] = useState(true)
  const [isTooltipOpen, setTooltipIsOpen] = useState(false)
  const [isFullScreenExpanded, setIsFullScreenExpanded] = useState(false)
  // whether live chat features should be visible/available at all
  const isLiveChatAvailable = (liveChat && liveChat.organizationId && liveChat.referenceId)

  const [cardVideoViews, setCardVideoViews] = useState(0)
  const [cardLikes, setCardLikes] = useState(0)

  const [isControlBarHidden, hideControlBar] = useState(true);
  const [isLiveIndicatorShrinked, shrinkLiveIndicator] = useState(true)
  const handleVolumeChange = useCallback((vol) => {
    // // save initial livestream value from local storage
    // if (vol !== 1 && vol !== 0) {
    //   setLivestreamVolume(vol)
    // }
    // if (isInitialLivestream && liveStreamConnected) {
    //   if (playerRef.current.video.video.volume == 1 && livestreamVolume != 1) {
    //     playerRef.current.video.video.volume = livestreamVolume
    //     setVolume(livestreamVolume)
    //     return
    //   }
    //   setIsInitialLivestream(false)
    // }
    setVolume(vol)
  })

  const closeReminder = useCallback(() => {
    dispatch(setGridAreaContent('Grid'))
  }, [dispatch])

  const closeFollowForm = useCallback(() => {
    dispatch(setGridAreaContent('Grid'))
  }, [dispatch])

  function blockRightClick(event) {
    event.preventDefault()
  }
  let history = useHistory()
  const { isLandscape } = useMobileOrientation()

  useEventListener('contextmenu', blockRightClick, playerRef?.current)

  useEffect(() => {
    try {
      const currentUrl = new URL(
        header?.liveStreamUrl || header?.videoUrl || '',
      )?.pathname
      let currentSrc = playerRef?.current?.video?.video?.currentSrc
      const videoUrl = new URL(
        currentSrc?.startsWith('blob:') ? currentLiveStreamUrl : currentSrc || '',
      )?.pathname
      if (prevVideoHeading !== currentUrl || currentUrl !== videoUrl) {
        setPrevVideoHeading(videoUrl)
        playerRef?.current?.load()
      } else {
        setPrevVideoHeading(videoUrl)
        playerRef?.current?.play()
      }
    } catch (err) {
      log(`Error handling currentUrl`, err)
    }
    // }
  }, [header, items, playerRef])

  const [videoPlayerSourceOrientation, setVideoPlayerSourceOrientation] = useState("Horizontal")
  const [videoPlayerStateListenerInitialized, setVideoPlayerStateListenerInitialized] = useState(false)

  useEffect(() => {
    const timer = setInterval(() => {
      if (playerRef.current) {
        let width = playerRef.current.getState().player.videoWidth
        let height = playerRef.current.getState().player.videoHeight

        let resolvedOrientation = "Horizontal"
        if (height > width) {
          resolvedOrientation = "Vertical"
        }
        if (resolvedOrientation !== videoPlayerSourceOrientation) {
          setVideoPlayerSourceOrientation(resolvedOrientation)
        }
      }
    }, 500)
    return () => clearInterval(timer)
  }, [playerRef.current, videoPlayerStateListenerInitialized, videoPlayerSourceOrientation])

  useEffect(() => {
    log(`Current orientation = ${videoPlayerSourceOrientation}`)
  }, [videoPlayerSourceOrientation]);

  useEffect(() => {

    let c = cardUuid
    if (liveData && liveData.cards && liveData.cards[c]) {
      clickLike(liveData.cards[c].liked === true)
    }

    setCardLikes(liveData?.cards[c]?.likes ?? statistics.totalSocialLikes)
    setCardVideoViews(liveData?.cards[c]?.views ?? statistics.totalViews)

  }, [liveData, cardUuid, header])

  useEffect(() => {
    const timer = setInterval(() => {
      // continuously append timestamps
      localStorageAppendToTimestamps(cardUuid)
    }, 1000)
    return () => clearInterval(timer)
  }, [cardUuid])

  useEffect(() => {
    if (liveStream?.expandedByDefault) {
      if (gridAreaContent === 'LiveChat') {
        dispatch(setGridAreaContent('Grid'))
      }
      dispatch(setGridAreaContent('LiveStreamReminderForm'))
    }
  }, [])

  const goBack = useCallback(() => {
    dispatch(setIsLoading({ isLoading: false }))
    dispatch(setIsSetVideoCurrentTime({ isSetVideoCurrentTime: false }))
    dispatch(navigateCard(parentCardId, history, "User"))
  }, [dispatch, window, cardUuid, sequencePollUuid, parentCardId, history])

  const handleMessage = event => {
    if (event?.data?.type === 'volume') {
      if (event.data?.value?.isPaused) {
        setIsPaused(true)
      }
      if (event?.data?.value?.volume > 0) {
        if (playerRef.current?.video?.video?.volume !== undefined) {
          playerRef.current.video.video.volume = event?.data?.value?.volume
        }
      }
      handleVolumeChange(event?.data?.value?.volume)
      if (event?.data?.value?.isMuted) {
        setPlayerVolume(0)
      } else {
        setPlayerVolume(event?.data?.value?.volume)
      }

      if (event?.data?.value?.muted === true) {
        if (event?.data?.value?.reportEvent)
          ws.sendEvent(EventInteractionType.HEADER_VIDEO_MUTED)
      } else {
        if (!event.data?.value?.isPaused) {
          if (event?.data?.value?.reportEvent)
            ws.sendEvent(EventInteractionType.HEADER_VIDEO_UNMUTED)
          try {
            playerRef?.current?.play()
          } catch (e) {
            log(`NotAllowedError??`, e)
            // NotAllowedError
            // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
          }
        }
      }
    }
    if (event?.data?.type === 'widget-closed') {
      ws.sendEvent(EventInteractionType.WIDGET_CLOSED)
    }
    if (event?.data?.type === 'widget-maximized') {
      if (event?.data?.value?.reportEvent) {
        const type =
          event?.data?.value?.status === true
            ? EventInteractionType.WIDGET_MAXIMIZED
            : EventInteractionType.WIDGET_MINIMIZED
        ws.sendEvent(type)
      }
      dispatch(
        setIsWidgetMaximized({
          isWidgetMaximized: event?.data?.value?.status,
        }),
      )
    }
    if (event?.data?.type === 'qa-st') {
      sendToWidget(
        { type: 'volume-status',
          value: {
            status: playerRef?.current?.muted || volume
          }
        },
      )
    }
    if (event?.data?.type === 'save-time') {
    }
    if (event?.data?.type === 'widget-state') {

      let state = event?.data?.value?.state
      dispatch(setUserEmail({ userEmail: state?.userEmail }))
    }
  }

  const fullscreenScreenChange = type => {
    if (type === 'MAXIMIZED') {
      ws.sendEvent(EventInteractionType.HEADER_VIDEO_MAXIMIZED)
    } else {
      ws.sendEvent(EventInteractionType.HEADER_VIDEO_MINIMIZED)
    }
  }

  const leavingSite = () => { }

  useEffect(() => {
    window.addEventListener('message', handleMessage, true)
    return () => {
      window.removeEventListener('message', handleMessage, true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', leavingSite)
    return () => {
      window.removeEventListener('beforeunload', leavingSite)
    }
  }, [])

  useEffect(() => {
    addObserver()
  }, [isWidgetMaximized])

  const addObserver = () => {
    const control = document.querySelector('.video-react-fullscreen-control')
    if (control) {
      const options = {
        attributes: true,
      }

      function callback(mutationList, observer) {
        mutationList.forEach(function (mutation) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'class'
          ) {
            if (mutation?.target?.className?.includes('fullscreen-exit')) {
              fullscreenScreenChange('MAXIMIZED')
            } else {
              fullscreenScreenChange('MINIMIZED')
            }
          }
        })
      }

      const observer = new MutationObserver(callback)
      observer.observe(control, options)
    }
  }

  const handleVideoVolumeStatus = useCallback(() => {
    if (isPaused) {
      playerRef.current?.video?.video?.pause()
    }
    if (isInitial) {
      const video = playerRef?.current?.video?.video
      if (video.paused) {
        let tempVolume = volume
        let promise
        try {
          promise = video?.play()
        } catch (e) {
          log(`NotAllowedError??`, e)
          // NotAllowedError
          // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
        }
        promise
          ?.then(res => {
            handleVolumeChange(tempVolume)

            if (video.paused) {
              tempVolume = 0
              handleVolumeChange(tempVolume)
              try {
                video?.play()
              } catch (e) {
                log(`NotAllowedError??`, e)
                // NotAllowedError
                // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
              }
            }
            sendToWidget(
              {
                type: 'volume-status',
                value: {
                   tempVolume: tempVolume
                }
              },
            )
          })
          .catch(err => {
            log(`Error handing volume status`, err)
            sendToWidget({ type: 'volume-status', value: { volume: volume } })
            handleVolumeChange(0)
            setPlayerVolume(0)
            try {
              video?.play()
            } catch (e) {
              log(`NotAllowedError??`, e)
              // NotAllowedError
              // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
            }
          })
      } else {
        setPlayerVolume(volume)
        sendToWidget({ type: 'volume-status', value: { volume: volume }})
      }
    } else {
      sendToWidget({ type: 'volume-status', value: { volume: volume } })
    }
  }, [isPaused, playerRef, window, volume, isInitial])

  const setPlayerVolume = volume => {
    if (playerRef?.current?.video?.video?.volume !== undefined) {
      if (volume === 0 && playerRef.current.video?.video?.muted !== undefined) {
        playerRef.current.video.video.muted = true
        return
      }

      if (volume > 0) {
        if (
          playerRef.current?.video?.video?.muted !== undefined &&
          playerRef.current?.video?.video !== undefined
        ) {
          playerRef.current.video.video.muted = false
          playerRef.current.video.video.volume = volume
        }
      }
    }
  }

  useEffect(() => {
    loadVideoTime(cardUuid)
    sendToWidget({ type: 'q-volume', value: {} })
  }, [playerRef.current])


  useEffect(() => {
    sendToWidget(
      {
        type: 'has-video-res',
        value: {
          status: header?.videoUrl || header?.liveStreamUrl ? true : false,
        }
      },
    )
  }, [header])

  // hardcoded value for possibility to control highlighting from contester panel
  const allowHighlight = false

  const handleUnmuteReminder = useCallback(() => {
    if (playerRef.current?.video?.video?.muted !== undefined) {
      playerRef.current.video.video.muted = false
      hideControlBar(false);
      shrinkLiveIndicator(false);
      setTimeout(() => {
        hideControlBar(true)
        shrinkLiveIndicator(true)
      }, 3000)
    }
  }, [playerRef, hideControlBar, shrinkLiveIndicator])

  const headerType = useMemo(() => {
    let h = 'Video'
    if (header?.liveStreamUrl) {
      h = 'LiveStream'
    } else if (header?.videoUrl) {
      h = 'Video'
    } else if (header?.imageUrl) {
      h = 'Image'
    } else {
      h = 'Title'
    }

    return h
  }, [header])

  const [liveStreamConnected, setLiveStreamConnected] = useState(false)

  const [liveChatLatestMessages, setLiveChatLatestMessage] = useState([]);
  const [pinned, setPinned] = useState()
  const liveStreamConnectedCallback = () => {
    setLiveStreamConnected(true)
  }

  useEffect(() => {
    if (liveStreamConnected && playerRef && playerRef.current) {
      playerRef.current.video.video.play()
      ws.clearMetrics(MetricsType.videoPaused)
      ws.clearMetrics(MetricsType.videoPlaying)
      ws.registerMetrics(MetricsType.liveStreamPlaying, () => {
        return true
      })
      ws.registerMetrics(MetricsType.videoMuted, () => {
        return playerRef?.current?.video?.video?.volume === 0.0
      })
      ws.registerMetrics(MetricsType.videoUnmuted, () => {
        return playerRef?.current?.video?.video?.volume !== 0.0
      })
    } else if (header?.videoUrl) {
      ws.clearMetrics(MetricsType.liveStreamPlaying)
      ws.registerMetrics(MetricsType.videoPlaying, () => {
        return playerRef?.current?.video?.video?.paused !== true
      })
      ws.registerMetrics(MetricsType.videoPaused, () => {
        return playerRef?.current?.video?.video?.paused === true
      })
      ws.registerMetrics(MetricsType.videoMuted, () => {
        return playerRef?.current?.video?.video?.muted === true
      })
      ws.registerMetrics(MetricsType.videoUnmuted, () => {
        return playerRef?.current?.video?.video?.muted !== true
      })
    }
  }, [liveStreamConnected, header?.videoUrl, playerRef])

  useEffect(() => {
    liveChatData();
  }, [liveChatHistories, liveChat]);

  const liveChatData = () => {
    const activeLiveChat = liveChatHistories.find(
        (h) => h.referenceId === liveChat?.referenceId,
    )
    let messagesToShow = [];
    let pinned;
    if (activeLiveChat) {

      // in messagesToShow only include non-pinned messages
      let messagesToShowLimit = isMobile ? 3 : 4
      if (activeLiveChat.history.length > 0) {
        for (let i = activeLiveChat.history.length - 1; i >= 0; i--) {
          let msg = activeLiveChat.history[i]
          let messagesToConsider = [msg]
          if (msg.replies.length > 0) {
            messagesToConsider.push(...msg.replies)
          }
          for (let k = messagesToConsider.length - 1; k >= 0; k--) {
            if (!messagesToConsider[k].pinned) {
              messagesToShow.push(messagesToConsider[k])
              if (messagesToShow.length >= messagesToShowLimit) {
                break
              }
            }
          }
          if (messagesToShow.length >= messagesToShowLimit) {
            break
          }
        }
      }
      pinned = activeLiveChat.pinned
    }
    setLiveChatLatestMessage(messagesToShow.reverse())
    setPinned(pinned)
  }

  const updateLivestreamSeekTime = (state, prevState) => {
    // const prev = Math.floor(prevState.currentTime)
    // const current = Math.floor(state.currentTime)
    let prev = new Date().getTime()
    if (liveStream?.connectedAt) {
      prev = liveStream?.connectedAt * 1000
    }
    const current = new Date().getTime()
    const elapsed = Math.floor(current - prev) / 1000
    if (
      elapsed > 0 &&
      livestreamSeekTime != elapsed &&
      elapsed != 0
    ) {
      setLivestreamSeekTime(elapsed)
    }
  }

  useEffect(() => {
    let liveStreamUrl = header?.liveStreamUrl

    if (!liveStreamUrl) {
      setLiveStreamConnected(false)
      setCurrentLiveStreamUrl(undefined)
    } else {
      // we still want to reload the livestream given that url has changed
      setLiveStreamConnected(false)
    }

    let load = () => {
      const player = window.IVSPlayer.create()
      // set desired playback quality if specified in BE. This controls resolution by device types
      let desiredQuality = "720p"
      if (isMobile) {
        desiredQuality = liveStream?.playbackQualityMobile ?? "540p"
      } else {
        desiredQuality = liveStream?.playbackQualityDesktop ?? "720p"
      }
      if (desiredQuality) {
        player.addEventListener(
          window.IVSPlayer.PlayerState.PLAYING,
          () => {
            const quality = player.getQuality()
            if (quality.name !== desiredQuality) {
              const qualities = player.getQualities()
              let newQuality = qualities.find(
                q => q.name === desiredQuality,
              )
              if (newQuality) {
                log(newQuality)
                player.setQuality(newQuality)
              }
            }
            liveStreamConnectedCallback()
          },
        )
      }

      initMuxData(playerRef.current.video.video)
      player.attachHTMLVideoElement(playerRef.current.video.video)
      player.load(liveStreamUrl)
      player.play()
      setCurrentLiveStreamUrl(liveStreamUrl)
      hideControlBar(false)
      setPrevVideoHeading(new URL(liveStreamUrl).pathname)

      playerRef?.current?.subscribeToStateChange(updateLivestreamSeekTime)
    }

    // it is possible that we receive Connected event BEFORE stream is actually available.
    // So let's check every second until playback url response status changes from 404 to 200.
    let checkStatus = setInterval(() => {
      if (liveStreamUrl) {
        fetch(liveStreamUrl).then(response => {
          if (response.status < 400) {
            clearInterval(checkStatus)
            load()
          }
        })
      }
    }, 1000)
    return () => clearInterval(checkStatus)
  }, [header?.liveStreamUrl])

  const initMuxData = useCallback((videoElement) => {
    const initTime = mux.utils.now();
    if (currentLiveStreamUrl) {
      mux.monitor(videoElement, {
        debug: false,
        data: {
          env_key: muxEnvKey,
          viewer_user_id: sessionData?.id,
          player_name: 'Live stream player',
          player_init_time: initTime,
          video_stream_type: 'live',
          video_id: liveStream?.id,
          video_title: liveStream?.title,
        }
      });
    } else {
      mux.monitor(videoElement, {
        debug: false,
        data: {
          env_key: muxEnvKey,
          viewer_user_id: sessionData?.id,
          player_name: 'Pre-recorded player',
          player_init_time: initTime,
          video_stream_type: 'on-demand',
          video_id: header?.videoUrl,
          video_title: header?.videoUrl,
        }
      });
    }
  }, [currentLiveStreamUrl, muxEnvKey, sessionData, liveStream, header])

  const handleShareButton = useCallback((e) => {

    let url = shareUrl ?? widgetLocation ?? window.location.href
    let shareData = {
      url: url,
      title: 'Share this video experience!',
    }
    if (navigator.canShare && navigator.canShare(shareData)) {
      ws.sendEvent(EventInteractionType.SOCIAL_SHARE)
      navigator
        .share(shareData)
        .then(() => {
          log('Sharing successful')
        })
        .catch(() => {
          log('Sharing failed')
        })
    } else if (navigator.clipboard) {
      setTooltipIsOpen(true)
      navigator.clipboard.writeText(url).then(() => {
        setTimeout(() => {
          setTooltipIsOpen(false)
        }, 1200)
      }, () => {
        log('cannot copy url')
      });
    }
  }, [shareUrl, widgetLocation, window, navigator, ws])

  const handleLikeClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    ws.sendEvent(EventInteractionType.SOCIAL_LIKE)
    if (!isLikeClicked) {
      setCardLikes(cardLikes+1)
    }

    clickLike(true)
    disableLikeBtn(true)
    setTimeout(() => {
      disableLikeBtn(false)
    }, 700)
  }

  const onPauseChangeHandler = value => {
    sendToWidget({ type: 'pause-change', value: { status: value } })
  }

  const showChat = useCallback(() => {
    dispatch(setGridAreaContent('LiveChat'));
  }, [gridAreaContent, dispatch])

  const onVolumeChange = useCallback((e) => {
    if (headerType === 'LiveStream') {
      hideControlBar(false)
    }
    let tempVol = e?.target?.volume
    let muted = e.target?.muted === true
    if (muted) {
      tempVol = 0
    }
    else if (tempVol === 0) {
      tempVol = 0.5
    }
    if (isWidgetMaximized) {
      if (volume === 0 && tempVol !== 0) {
        ws.sendEvent(EventInteractionType.HEADER_VIDEO_UNMUTED)
      }
      if (volume !== 0 && tempVol === 0) {
        ws.sendEvent(EventInteractionType.HEADER_VIDEO_MUTED)
      }
    }
    sendToWidget(
        { type: 'volume-status', value: { volume: tempVol } },
    )
    setPlayerVolume(tempVol)
    handleVolumeChange(tempVol)
  }, [volume, isWidgetMaximized, ws])

  const toggleHeaderFullscreen = useCallback(() => {

    let newMode = !isFullScreenExpanded

    setIsFullScreenExpanded(newMode)

    // can't go fullscreen on a phone. But keep the code, we will likely re-enable this later

    // if (isMobile) {
    //   if (newMode) {
    //     document.body.requestFullscreen().then(res=> {
    //       window.screen.orientation.lock('landscape')
    //     }).catch(err=>log(err));
    //   } else {
    //     document.exitFullscreen().then(res => {
    //       window.screen.orientation.unlock()
    //     })
    //   }
    // }

  }, [isFullScreenExpanded])

  const clickShowReminderForm = useCallback(() => dispatch(setGridAreaContent('LiveStreamReminderForm')), [dispatch])

  const headerOverlay = useMemo(() => {

    return (
        <>
          { isWidgetMaximized && (
              <div className="sequence-poll-controls">

                { liveStreamConnected && (
              <div className={classnames("livestream-indicator", {'is-shrinked': isLiveIndicatorShrinked })}>
                      {formatTime(livestreamSeekTime)}
                      <span className="live-dot"></span>
                <span className="live-label">
                LIVE
                </span>
                    </div>
                )}

                {(
                    !isEmpty(liveStream) && <div className="sequence-poll-control" onClick={clickShowReminderForm}>
                      <div className={classnames("reminder-indicator", { 'hide-label': !isNotifiedMessageVisible })}>
                      {isNotifiedMessageVisible && <span className="label">{liveStreamSubscribed ? translations['SubscriptionIconSubscribedLabel'] : translations['SubscriptionIconNonSubscribedLabel']}</span>}
                        <img
                            
                            src={asset('icon-notifications.svg')}
                        />
                      </div>
                    </div>
                )}

                { isLiveChatAvailable && (
                    <div className="sequence-poll-control" onClick={showChat}>
                      <img
                         
                          src={asset('Icon-message-circle.svg')}
                      />
                    </div>
                )}

                { (navigator.share || navigator.clipboard) && (
                    <div className="sequence-poll-control" onClick={handleShareButton}>
                      <Tooltip
                          id="header-share-tooltip"
                          content="Url copied"
                          isOpen={isTooltipOpen}
                          place="left"
                      />
                      <a
                          data-tooltip-id="header-share-tooltip"
                          data-tooltip-content="Url was copied"
                      >
                        <img
                           
                            src={asset('icon-share.svg')}
                        />
                      </a>
                    </div>
                )}
                <div className="sequence-poll-control" onMouseDown={(e) => handleLikeClick(e)}>
                  <div className="control-label">{cardLikes}</div>
                  <div className="control-container">
                    <button
                        disabled={isLikeBtnDisabled}
                        className="like-button"
                    >
                          <span >
                            <img
                                src={asset(
                                    `${isLikeClicked
                                        ? 'icon-like-full.svg'
                                        : 'icon-like.svg'
                                    }`,
                                )}
                            />
                          </span>
                    </button>
                  </div>
                </div>

                { !isMobile && IS_STAGING && (
                    <div className="sequence-poll-control" onClick={toggleHeaderFullscreen}>
                      <img
                         
                          src={isFullScreenExpanded ? asset('ff-expand.svg') : asset('ff-compress.svg') }
                      />
                    </div>
                )}
                <div className="sequence-poll-control view-only">
                  <div className="control-label">

                    { (currentLiveStreamUrl && liveStreamConnected) ? (
                      <>
                        {liveData.liveStreamViewers}
                      </>
                    ) : (
                      <>
                        {cardVideoViews}
                      </>
                    )}
                  </div>
                  <div className="control-container">
                    <img src={asset('icon-view.svg')} />
                  </div>
                </div>
              </div>
          )}
        </>
    )
  },
      [
        isWidgetMaximized,
        liveStreamConnected,
        livestreamSeekTime,
        liveStream,
        isNotifiedMessageVisible,
        liveStreamSubscribed,
        dispatch,
        isLiveChatAvailable,
        navigator,
        isTooltipOpen,
        isLikeBtnDisabled,
        isLikeClicked,
        isMobile,
        isFullScreenExpanded,
        statistics,
        headerType,
        cardVideoViews,
        cardLikes,
        liveData,

      ])

  const handleInteractWithVideo = () => {
    playerRef?.current?.play()
    handleUnmuteReminder()
  }

  useEffect(() => {
    const timeout = setTimeout(() => {

      if (!videoPlayerInitiated) {
        setVideoPlayerWorking(false)
      }
    }, 2000)

    return () => clearTimeout(timeout)
  }, [videoPlayerWorking, videoPlayerInitiated])


  const videoOverlay = useMemo(() => {

    return (
        <>
          { !videoPlayerWorking && (
              <button
                  className={"interact-with-video-button"}
                  onClick={handleInteractWithVideo}
              >
                {translations['VideoPlayerButtonInteractLabel']}{' '}
              </button>
          )}

          {!volume && isWidgetMaximized && (
              <button
                  className="unmute-button"
                  onClick={handleUnmuteReminder}
              >
                {translations['VideoPlayerButtonUnmuteLabel']}{' '}
                <img src={asset('unmute.svg')} className="unmute" />
              </button>
          )}

          { currentLiveStreamUrl && !liveStreamConnected && (
              <img
                  style={{ position: 'absolute', objectFit: 'none' }}
                  src={asset('stream-loading-1.gif')}
              />
          )}

          { isLiveChatAvailable && (!isEmpty(liveChatLatestMessages) || !isEmpty(pinned)) &&
            <PinnedMessages
              isLiveChatVisible={gridAreaContent === 'LiveChat'}
              brand={brand}
              showChat={showChat}
              liveChatLatestMessages={liveChatLatestMessages}
              pinned={pinned}
              isControlBarHidden={isControlBarHidden}
              currentLiveStreamUrl={currentLiveStreamUrl}
            />}
      </>
    )
  }, [
    videoPlayerWorking,
    volume,
    isWidgetMaximized,
    currentLiveStreamUrl,
    liveStreamConnected,
    isLiveChatAvailable,
    liveChatLatestMessages,
    pinned,
    gridAreaContent,
    brand,
    isControlBarHidden
  ])

  const playerOnPlay = useCallback((e) => {

    if (!videoPlayerInitiated) {
      setVideoPlayerInitiated(true)
    }

    if (!videoPlayerWorking) {
      setVideoPlayerWorking(true)
    }

     /*  setTimeout(() => { */
        hideControlBar(true)
      /* }, 3000) */
    onPauseChangeHandler(false)
    // If widget is maximized it means we triggered click from here
    // Else widget is not maximized it means we automatically played video when user unmuted widget
    if (!isInitial && isWidgetMaximized && !isPaused && !currentLiveStreamUrl && cardUuid) {
      ws.sendEvent(EventInteractionType.HEADER_VIDEO_UNPAUSED, cardUuid)
    } else {
      setIsInitial(false)
    }
   
  }, [isInitial, isWidgetMaximized, isPaused, currentLiveStreamUrl, ws, videoPlayerWorking])

  const playerOnPause = useCallback((e) => {
    hideControlBar(false);
    onPauseChangeHandler(true)
    if (!currentLiveStreamUrl && cardUuid) {
      ws.sendEvent(EventInteractionType.HEADER_VIDEO_PAUSED, cardUuid)
    }
    if (!isSetVideoCurrentTime && !currentLiveStreamUrl) {
      dispatch(
          setVideoCurrentTime({
            prevVideoCurrentTime:
            document.querySelector('.video-react-video')
                ?.currentTime,
          }),
      )
      dispatch(
          setIsSetVideoCurrentTime({
            isSetVideoCurrentTime: true,
          }),
      )
    }
  }, [currentLiveStreamUrl, ws, isSetVideoCurrentTime, dispatch, hideControlBar, cardUuid])

  const preventDefault = useCallback((e) => e.preventDefault(), [])

  const playerOnLoadedData = useCallback(() => {
    handleVideoVolumeStatus()
    if (!currentLiveStreamUrl) {
      loadVideoTime(cardUuid)
    }
    else {
      hideControlBar(true)
      shrinkLiveIndicator(true)
    }
    initMuxData(playerRef.current.video.video)
  }, [currentLiveStreamUrl, cardUuid, playerRef])

  const liveStreamingPlayerConfig = useMemo(() => {
    return { duration: 1, currentTime: 1, seekingTime: 1 }
  }, [])


  const playerControlBar = useMemo(() => {

    return (
        <ControlBar className={classnames("control-bar", {visible: !isControlBarHidden})} disabled={isWidgetEmbedded && !isWidgetMaximized}>
          <CurrentTimeDisplay disabled />
          <TimeDivider disabled />
          <PlayToggle disabled />
          <DurationDisplay disabled />
          <FullscreenToggle disabled />
          <ProgressControl
              className={currentLiveStreamUrl ? 'livestream-tooltip' : ''}
              disabled={(isWidgetEmbedded && !isWidgetMaximized) || currentLiveStreamUrl}
          />
          {/*{ currentLiveStreamUrl ? (*/}
          {/*    <ProgressControl*/}
          {/*        className={'livestream-tooltip'}*/}
          {/*        player={liveStreamingPlayerConfig}*/}
          {/*        disabled={(isWidgetEmbedded && !isWidgetMaximized)}*/}
          {/*    />*/}
          {/*) : (*/}
          {/*    <ProgressControl*/}
          {/*        disabled={(isWidgetEmbedded && !isWidgetMaximized)}*/}
          {/*    />*/}
          {/*)}*/}
        </ControlBar>
    )
  }, [isControlBarHidden, isWidgetEmbedded, isWidgetMaximized, currentLiveStreamUrl, liveStreamingPlayerConfig, playerRef])

  const playerElement = useMemo(() => {

    return (
        <>
          <Player
              onContextMenu={preventDefault}
              loop={true}
              ref={playerRef}
              playsInline
              playsinline
              muted={Boolean(currentLiveStreamUrl)}
              autoPlay={true}
              webkit-playsinline
              width="100%"
              height="100%"
              // className={liveStreamConnected ? "live-stream-connected" : "live-stream-disconnected"}
              // style={{ display: liveStreamConnected ? 'block' : 'none' }}
              onVolumeChange={onVolumeChange}
              fluid={false}
              poster={undefined}
              onLoadedData={playerOnLoadedData}
              onPlay={playerOnPlay}
              onPause={playerOnPause}
         //     startTime={currentLiveStreamUrl ? 0 : isSetVideoCurrentTime ? 0 : prevVideoCurrentTime}
          >
            { !currentLiveStreamUrl && (
                <source src={header?.videoUrl} />
            )}
            <BigPlayButton disabled />
            { playerControlBar }
            { headerOverlay }
          </Player>
          { videoOverlay }
        </>
    )
  }, [
      playerRef,
      currentLiveStreamUrl,
      header,
      headerType,
      isWidgetEmbedded,
      isWidgetMaximized,
      videoOverlay,
      headerOverlay,
      playerOnPlay,
      playerOnPause,
      playerOnLoadedData,
      onVolumeChange,
      isControlBarHidden,
      liveStreamConnected,
      liveStreamingPlayerConfig,
      playerControlBar,
  ])

  const headerOnMouseDown = useCallback((e) => {
    // prevent livestream from gaining ANY focus. Make sure this also works on mobiles
    if (
      // Check that click was not on video-react element during live stream
      headerType === 'LiveStream' &&
       e.target.className.includes('video-react-video') && isControlBarHidden
    ) {
      hideControlBar(false)
      shrinkLiveIndicator(false)
      setTimeout(() => {
        hideControlBar(true)
        shrinkLiveIndicator(true)
      }, 3000)
      e.preventDefault()
    }
    else if (headerType === 'LiveStream' && e.target.className.includes('video-react-volume-menu-button')) {
      shrinkLiveIndicator(true)
      setTimeout(() => {
        hideControlBar(true)
      }, 3000)
    }
  }, [headerType, hideControlBar, shrinkLiveIndicator, isControlBarHidden])

  const showReminderFormClick = useCallback(() => {
    dispatch(setGridAreaContent('LiveStreamReminderForm'))
  }, [dispatch])

  const headerTitleStyles = useMemo(() => {
    return {
      ...titleStyle,
      display: 'flex',
      width: '100%',
      color: titleStyle?.color,
      fontWeight: titleStyle?.fontWeight,
      fontStyle: titleStyle?.fontStyle,
      fontFamily: titleStyle?.fontFamily,
      justifyContent:
          titleStyle?.aligment === 'right'
              ? 'end'
              : titleStyle?.aligment === 'left'
                  ? 'start'
                  : 'center',
    }
  }, [titleStyle])

  const arrowBackStyles = useMemo(() => {
    return { fontSize: '4vh' }
  }, [])

  const buttonBackStyles = useMemo(() => {
    return {
      cursor: 'pointer',
      opacity: '1',
    }
  }, [])

  return (
    <>
      <TopBar hasTitle={headerType === 'Title'} />
      <div
        className={classnames('content', {
          'has-title': headerType === 'Title',
          'has-image': headerType === 'Image',
        })}
      >
        <div className={classnames('photo-container', isFullScreenExpanded ? 'fullscreen' : '')}>
          <header
            onContextMenu={e => e.preventDefault()}

            onMouseDown={headerOnMouseDown}
            className={header === null ? 'hide' : 'show'}
          >
            {!isEmpty(liveStream) && !liveStreamConnected && !isWidgetMaximized && (
              <div
                className={classnames('scheduled-livestream')}
              >
                <Countdown
                  liveStream={liveStream}
                  showReminderForm={showReminderFormClick}
                  isReminderFormVisible={gridAreaContent === 'LiveStreamReminderForm'}
                  showNotifiedMessage={showNotifiedMessage}
                  isWidgetMaximized={isWidgetMaximized}
                  isSubscribed={liveStreamSubscribed}
                />
              </div>
            )}

            {/* the reason we have separate playerElement() invocations is because mess around video / livestream player initialization. We can't easily reuse the same element */}

            {headerType === 'LiveStream' && (
                <>
                  { playerElement }
                </>
            )}

            {headerType === 'Video' && (
                <>
                  { playerElement }
                </>
            )}

            {headerType === 'Image' && (
              <>
                {' '}
                <img alt="Poster" src={header?.imageUrl} />
                { headerOverlay }
              </>
            )}
            {header?.title && (
              <div
                style={headerTitleStyles}
              >
                {title?.split('|').length > 1 ? (
                  <>
                    <h2 className="title">{title?.split('|')[0]}</h2>
                    <h3 className="subtitle">{title?.split('|')[1]}</h3>
                  </>
                ) : (
                  <h3 className="title">{title?.split('|')[0]}</h3>
                )}
              </div>
            )}
          </header>
          <div className="carousel-nav">
            <div className="spacer-y" />
            {
              <button
                onClick={goBack}
                disabled={!cardUuid || !parentCardId}
                className={classnames({
                  'is-highlighted': allowHighlight && cardUuid,
                  'back-button-animation':
                    allowHighlight && cardUuid,
                })}
                style={buttonBackStyles}
              >
                <ArrowBackIosRoundedIcon style={arrowBackStyles} />
              </button>
            }
          </div>
          <div className="right-section-container">
            <div className={classnames("carousel-nav-mobile", { hidden: !parentCardId })}>
              <button
                  onClick={goBack}
                  disabled={!cardUuid || !parentCardId}
                  className={classnames({
                    'is-highlighted': allowHighlight && cardUuid,
                    'back-button-animation':
                        allowHighlight && cardUuid,
                  })}
                  style={buttonBackStyles}
              >
                <ArrowBackIosRoundedIcon style={{ fontSize: '4vh', color:'#ffffff' }}/>
              </button>
            </div>
            {!isEmpty(liveChat) && isSessionEstablished && sequencePollUuid && (
              <ChatContainer
                  organizationId={liveChat.organizationId}
                  experienceId={sequencePollUuid}
                  referenceId={liveChat.referenceId}
              />
            )}
            {!isEmpty(liveStream) && isSessionEstablished && (
                <>
                  <div
                      className={classnames('reminder-form', {
                        show: gridAreaContent === 'LiveStreamReminderForm',
                      })}
                  >
                    <img
                        onClick={closeReminder}
                        src={asset('cross-white.svg')}
                        className="close-reminder"
                    />
                    <ReminderForm isCountdownFinished={new Date() > new Date(liveStream.scheduledAt * 1000)} showNotifiedMessage={showNotifiedMessage} isSubscribed={liveStreamSubscribed} />
                  </div>
                </>
            )}
            { isSessionEstablished && brand && Object.keys(translations).length > 0 && (
                <>
                  <div
                      className={classnames('reminder-form', {
                        show: gridAreaContent === 'BrandFollowForm',
                      })}
                  >
                    <img
                        onClick={closeFollowForm}
                        src={asset('cross-white.svg')}
                        className="close-reminder"
                    />
                    <BrandFollowForm />
                  </div>
                </>
            )}
            <PhotoGrid />
          </div>
        </div>

        <FooterLogo />
      </div>
    </>
  )
}

export default pure(Content)
