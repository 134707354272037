import React, {useCallback, useEffect, useRef, useState} from 'react'
import classnames from 'classnames'
import SingleComment from './SingleComment'
import MessageSendContainer from './MessageSendContainer'
import {
  liveChatAPI,
} from '../../api/liveChatAPI'
import { useStateValue } from '../state-provider/StateProvider'
import './ChatContainer.css'
import {isEmpty, isEqual} from 'lodash'
import { setLiveChatHistory } from './Chat.action'
import { asset } from '../../utils/utils'
import { LiveChatDataObject } from '../../events/ws'
import {pure} from "recompose";

import { Virtuoso } from 'react-virtuoso'
import {LiveChatHistory, LiveChatHistoryItem} from "../../api/types";
import {setGridAreaContent} from "../app/App.action";
import {log} from "../../utils/log";

export type JoinChatStep = 'name' | 'email' | 'joined'

const ChatContainer: React.FC<{
  organizationId: string
  experienceId: string
  referenceId: string
}> = ({ organizationId, experienceId, referenceId }) => {
  const [isHistoryLoading, setHistoryLoader] = React.useState(false)
  const [isInputClean, cleanInputs] = React.useState(false)
  const [joinChatStep, setJoinChatStep] = React.useState<JoinChatStep>('name')
  const [chatHistory, setChatHistory] = React.useState<LiveChatHistory>()
  const [liveChat, setLiveChat] = React.useState<LiveChatDataObject>()
  const [lastLiveChatMessage, setLastLiveChatMessage] = useState<LiveChatHistoryItem>()
  const [isUserNameInputFocused, focusUserNameInput] = React.useState(false)
  const [{ liveChats, liveChatHistories, gridAreaContent, liveChatMode, translations }, dispatch] =
    useStateValue()

  React.useEffect(() => {
    if (liveChatHistories && referenceId) {
      let history = liveChatHistories.find(
          (h: LiveChatHistory) => h.referenceId === referenceId,
        )

      if (!isEqual(chatHistory, history)) {
        setChatHistory(history)
        if (history) {
          if (history.history.length > 0) {
            for (var i = history.history.length - 1; i >= 0; i--) {
              let msg = history.history[i]
              if (msg && msg.id !== 'PENDING') {
                setLastLiveChatMessage(msg)
                break
              }
            }
          }
          if (divRef?.current) {
            divRef.current.scrollToIndex(history!!.history.length - 1, 'smooth')
          }
        } else {
          setLastLiveChatMessage(undefined)
        }
      }
    }
  }, [referenceId, liveChatHistories])

  const getChatHistory = async (hash: string) => {
    if (!isHistoryLoading && isEmpty(chatHistory)) {
      setHistoryLoader(true)
    }

    try {
      let res = await liveChatAPI.getHistory(
        organizationId,
        experienceId,
        referenceId,
        hash,
        lastLiveChatMessage?.id ?? null,
      )
      if (lastLiveChatMessage && (!chatHistory?.history || chatHistory.referenceId === referenceId)) { // it is possible that chatHistory and res.history contain different histories for different chats when moving from one card to another
        res = {
          ...res,
          // keep an eye on the below to make sure it is not causing performance degradation. For history of 1k+ messages this executed within less than a millisecond, so perhaps all good
          history: (chatHistory?.history?.filter(m => m.id !== 'PENDING') ?? []).concat(res.history)
        }
      }
      dispatch(setLiveChatHistory({ history: res }))
    } catch (e) {
      log(`Error getting chat history`, e)
    } finally {
      setHistoryLoader(false)
    }
  }

  const divRef = useRef<any>()

  React.useEffect(() => {
    if (organizationId && referenceId) {
      // this may be undefined if WS settings liveChats property has no joined livechats yet
      let resolvedLiveChat = liveChats.find(
        (c: LiveChatDataObject) => c.referenceId === referenceId,
      )
      setLiveChat(resolvedLiveChat)
      if (!resolvedLiveChat) {
        // not part of any live chat at the moment, but received live chat updated event on WS.
        // need to re-fetch current chat history nonetheless
        getChatHistory(Math.floor(new Date().getTime() / 1000).toString())
      }
    }
  }, [organizationId, referenceId, liveChats])

  useEffect(() => {
    const interval = setInterval(() => {
      if (liveChatMode !== 'RT') {
        getChatHistory(Math.floor(new Date().getTime() / 1000).toString())
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [organizationId, referenceId, liveChats, liveChatMode, lastLiveChatMessage, chatHistory])

  React.useEffect(() => {
    if (organizationId && referenceId && liveChat && liveChatMode === 'RT') {
      getChatHistory(liveChat.hash)
    }
  }, [organizationId, referenceId, liveChat, liveChatMode])

  React.useEffect(() => {
    if (divRef.current && !isEmpty(chatHistory) && (gridAreaContent === 'LiveChat')) {
      divRef.current.scrollTop = divRef?.current.scrollHeight
    }
  }, [chatHistory, divRef.current, gridAreaContent])

  const renderItem = useCallback((index: number, comment: LiveChatHistoryItem) => {

    return (
      <div key={comment.id} className={liveChat?.profile.id === comment.user.id && comment.replies.length > 0 ? 'is-reply-to-current-user' : ''}>
        <SingleComment

          key={`comment-${comment.id}`}
          createdAt={comment.createdAt}
          message={comment.message}
          reply={false}
          reactions={comment.reactions}
          picture={comment.user.picture}
          userId={comment.user.picture}
          name={comment.user.name}
        />
        {comment.replies
          ? comment.replies.map(
            (reply: LiveChatHistoryItem) => (
              <SingleComment
                key={`reply-${reply.id}`}
                createdAt={reply.createdAt}
                message={reply.message}
                reply
                reactions={reply.reactions}
                picture={reply.user.picture}
                userId={reply.user.picture}
                name={reply.user.name}
              />
            ),
          )
          : null}
      </div>
    )
  }, [liveChat])

  return (
    <>
      {organizationId && referenceId && (
        <div
          className={classnames('chat-container', {
            show: gridAreaContent === 'LiveChat',
          })}
        >
          {isHistoryLoading && isEmpty(chatHistory) ? (
            <div className="circle-loader" />
          ) : (
            <>
              <div className="live-chat-header">
                <span>{translations['LiveChatTitle']}</span>
                <img
                  onClick={() => dispatch(setGridAreaContent('Grid'))}
                  src={asset('cross.svg')}
                  className="close-chat"
                />
              </div>
              {(isUserNameInputFocused) && (
                <div className="block-screen" />
              )}
              { chatHistory?.history && (
                <Virtuoso
                  className={classnames('chat-messages', {})}
                  itemContent={renderItem}
                  ref={divRef}
                  data={chatHistory.history}
                  followOutput={'auto'}
                  initialTopMostItemIndex={(chatHistory?.history?.length ?? 0) - 1}
                />
              )}
            </>
          )}
          <MessageSendContainer
            isLiveChatVisible={gridAreaContent === 'LiveChat'}
            organizationId={organizationId}
            experienceId={experienceId}
            referenceId={referenceId}
            liveChat={liveChat}
            joinChatStep={joinChatStep}
            setJoinChatStep={setJoinChatStep}
            isInputClean={isInputClean}
            cleanInputs={cleanInputs}
            focusUserNameInput={focusUserNameInput}
            isUserNameInputFocused={isUserNameInputFocused}
          />
        </div>
      )}
    </>
  )
}
export default pure(ChatContainer)
