import { FC } from 'react'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import './CardProduct.css'

import { FiChevronRight } from 'react-icons/fi'
import {pure} from "recompose";

interface CardProductData {
  images: string[]
  oldPrice?: string
  price?: string
  title: string
  subtitle: string
}

const CardProduct: FC<{
  product: CardProductData
}> = ({ product }) => {
  const isImageAvailable = !isEmpty(product.images)
  const hasSubtitle = Boolean(product.subtitle)
  const displayOldPrice = Boolean(((product.price ?? "") + (product.oldPrice ?? "")).length <= 16)
  return (
    <div className={'card-product-container'}>
      {isImageAvailable && (
        <div className="card-product-image">
          <img alt="product image" src={product.images[0]} />
        </div>
      )}
      <div
        className={classnames('card-product-area-main', {
          'has-image': isImageAvailable,
          'has-subtitle': hasSubtitle,
        })}
      >
        <div className="card-product-header">
          <div className={'card-product-title'}>{product.title}</div>
          {hasSubtitle && (
            <div className={'card-product-subtitle'}>{product.subtitle}</div>
          )}
        </div>

        <div className={'card-product-price-container'}>
          {product.price && product.oldPrice && displayOldPrice ? (
            <>
              <span className={'card-product-oldprice'}>
                {product.oldPrice}
              </span>
              <span className={'card-product-price'}>{product.price}</span>
            </>
          ) : (
            product.price && (
              <>
                <span className={'card-product-price'}>{product.price}</span>
              </>
            )
          )}
        </div>
      </div>
      <div className={'card-product-area-right'}>
        <FiChevronRight />
      </div>
    </div>
  )
}

export default pure(CardProduct)
